<template>
  <div class="m-card3" v-if="list && list.length">
    <div class="mc-top">
      <div class="mc-top-title">
        <span class="mc-top-text mc-top-txt s-bold">Pictures</span>
      </div>
    </div>
    <div class="m-list2">
      <Row>
        <div class="clear" >
          <Col
            span="6"
            v-for="(item, index) in list"
            :key="'m-list2-1' + index"
            class="m-list2-box mien_item"
          >
            <div class="ml2-item" style="margin-bottom: 20px">
              <div class="ml2-item-box">
                <img
                  @click="showImg(index)"
                  v-if="item.url"
                  :src="item.url"
                  v-load="'oPic'"
                />
                <img v-else src="@/assets/img/empty.png" >
              </div>
            </div>
          </Col>
        </div>
        <div class="no_more mar_bot1" v-if="loading">
          <p>{{ $t('lang.loading')}}</p>
        </div>
        <!-- <div class="no_more mar_bot1" v-else-if="finished">
              <p>{{$t('lang.Its_the_end')}}</p>
      </div> -->
        <div
          class="more mar_bot1"
          @click="moreClick"
          v-else-if="!finished && list && list.length"
        >
          <p>{{$t('lang.loadMore')}}</p>
        </div>
        <!-- <div class="no_more mar_bot1" v-else>{{ $t('lang.No_data') }}！</div> -->
      </Row>
    </div>
    <ShowSwiper :show="imgShow" :imgIndex="imgI" :imgList="imgList">
    </ShowSwiper>
  </div>
</template>
 
<script>
import { mapActions, mapGetters } from "vuex";
import ShowSwiper from "@components/ShowSwiper/ShowSwiper";
import moment from "moment";
export default {
  name: "product-list",
  props: {},
  data() {
    return {
      list: [],
      totalCount: 0,
      pageIndex: 1,
      pageSize: 8,
      finished: false,
      loading: false,
      imgShow:false,
      imgList:[],
      imgI:0,
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
    }),
    exhibitorId() {
      return this.$route.params.id;
    },
  },
  components: {
    ShowSwiper
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXSEn",
    }),
    showImg(i){
      this.imgI=i;
      let arr=[];
      this.list.forEach(e => {
        arr.push(e.url)
      });
      this.imgList=arr;
      this.imgShow=true;
    },
    closeSwiper () {
      this.imgShow = false;
    },
    async getData() {
      this.loading = true;
      let query = `
        query($query:QueryInput!){
          exhibitorAlbumQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items{
                  id
                  showIndex
                  url
                }
              }
            }
        }
      `;
      let where = {
        Equal: {
          n: "exhibitorId",
          v: this.exhibitorId,
        },
      };
      let order = [
        {
          N: "showIndex",
          v: 0,
        },
        {
          N: "createAt",
          v: 0,
        },
      ];
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      let data = await this.graphqlPost(opt);
      let res = JSON.parse(JSON.stringify(data.data.exhibitorAlbumQuery.query));
      if (this.pageIndex == 1) {
        this.list = res.items;
      } else {
        this.list = [...new Set([...this.list, ...res.items])];
      }
      this.loading = false;
      if (!res.hasNextPage) {
        this.finished = true;
      }
    },
    //点击加载更多
    moreClick() {
      this.finished = true;
      return;
      if (this.finished) return;
      this.pageIndex += 1;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.mien_item {
  .ml2-item-box {
    border: none;
    width: 195px;
    height: 117px;
    padding-top: 0;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }
}
</style> 