<template>
<!-- :class="show?'open':''" -->
  <div class="modal-carousel" v-if="show" >
    <div class="modal-carousel-mask"></div>
    <div class="carousel">
      <Carousel v-model="value1" :loop="true" style="100vh" >
        <CarouselItem v-for="(item, index) in imgList" :key="index">
          <div class="demo-carousel">
            <img :src="item"  />
          </div>
        </CarouselItem>
      </Carousel>
    </div>

    <div class="guanbi" @click.stop="childMethod">
      <i class="iconfont icon-guanbi"> </i>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowSwiper",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    imgList: {
      type: Array,
      default:()=>{
        return ['https://img.zhanshangxiu.com/showonline/file/202011/20201126/e7cde6541db94d87928d95a99b51dd74.jpg']
      }
    },
    imgIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      value1: 0,
      isShow:false,
    };
  },
  methods: {
    childMethod() {
      this.value1 = 0;
      this.$parent.closeSwiper();
    },
  },
  watch: {
    show(val, oldVal) {
      if (val) {
        this.value1 = this.imgIndex;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.demo-carousel {
  width: 100vw;
  height:100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    display: block;
    max-height: 100%;
    max-width: 100%;
  }
}
.modal-carousel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
    z-index: 10000;
  // z-index: -10;
  // opacity: 0;
  // &.open{
  //   opacity: 1;
  // }
}

.guanbi {
  position: absolute;
  top: 20px;
  right: 60px;
  font-size: 36px;
  cursor: pointer;
  color: #a6a6a6;
}

.guanbi:hover {
  .icon-guanbi {
    color: #fff;
  }
}
.carousel {
  position: relative;
  width: 100vw;
  text-align: center;
  height: 100vh;
}

::v-deep.ivu-carousel {
  button {
    width: 72px;
    height: 72px;
  }

  .ivu-icon {
    font-size: 28px;
  }

  .ivu-carousel-dots-inside {
    display: none;
  }
}

.modal-carousel-mask {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.8);
  /*img{*/
  /*  */
  /*}*/
}
</style>
