<template>
  <div class="gSwiperBox2" v-if="list && list.length">
    <swiper :options="swiperOption" :ref="name" v-if="list">
      <swiper-slide v-for="(item, index) in list" :key="'gSwiperCommon-' + index" @click.native="linkTo(item)">
        <img :src="item.covers" v-if="item.covers" class="swiper-cover" :style="{ width: bannerWidth ? bannerWidth + 'px' : '', height: bannerHeight ? bannerHeight + 'px' : '' }" />
        <img src="@assets/img/empty.png" v-else class="swiper-cover" />
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev" v-if="navigation"></div>
      <div class="swiper-button-next" slot="button-next" v-if="navigation"></div>
    </swiper>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "gSwiperCommon",
  props: {
    bannerType: {
      type: String,
      default: "show.page.home.pc",
    },
    navigation: {
      // 是否显示 左右按钮
      type: Boolean,
      default: false,
    },
    bannerHeight: {
      // 是否显示 左右按钮
      type: Number,
      default: 0,
    },
    bannerWidth: {
      // 是否显示 左右按钮
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      list: null,
      pageSize: 20,
      pageIndex: 1,
      totalCount: 0,
      swiperOption: {
        // some swiper options/callbacks
        // 所有的参数同 swiper 官方 api 参数
        // ...
        spaceBetween: 0,
        loop: true,
        autoplay: {
          delay: 4000,
        },
        pagination: {
          clickable: true,
        },

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      name: "",
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
    }),
    swiper() {
      return this.$refs[this.name].swiper;
    },
  },
  created() {
    this.name = `mySwiper-${Math.random()}`;
    this.init();
  },
  methods: {
    ...mapActions({
      getBannerByGraphal: "banner/getBannerByGraphal",
    }),
    async init() {
      let listData = await this.getBannerByGraphal(this.bannerType);
      if (listData.bannerQuery && listData.bannerQuery.by) {
        listData.bannerQuery.by.sort((a,b)=>{return a.showIndex - b.showIndex});
        // console.log(listData.bannerQuery.by,'bannerbbbbnnn')
        this.list = listData.bannerQuery.by;
      }
    },
    linkTo(e) {
      if (e && e.url) {
        window.open(e.url);
      }
    },
  },
};
</script>

<style lang="scss">
.gSwiperBox2 {
  width: 100%;
  min-width: 1400px;
  background-color: #ddd;
  .swiper-slide {
    position: relative;
    img {
      width: 100%;
      height: 400px;
    }
  }
  .swiperBox-content {
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    position: absolute;
    padding: 25px;
    color: #fff;
  }
  .swiper-container {
    font-size: 0;
  }
  .swiper-pagination {
    font-size: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 18px;
    bottom: 22px;
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      border-radius: 0;
      background-color: #ddd;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background-color: #ed6e02;
      opacity: 1;
    }
  }
  .swiper-button-next {
    background-image: url("./img/arrow-r.png");
    background-size: contain;
    width: 44px;
  }
  .swiper-button-prev {
    width: 44px;
    background-image: url("./img/arrow-l.png");
    background-size: contain;
  }
}
</style>
