<template>
  <div class="m-card3" v-if="list && list.length">
    <div class="mc-top">
      <div class="mc-top-title">
        <span class="mc-top-text mc-top-txt s-bold">Activities</span>
      </div>
    </div>
    <div class="m-list2">
      <Row>
        <div class="clear" >
          <Col
            span="6"
            v-for="(item, index) in list"
            :key="'m-list2-1' + index"
            class="m-list2-box act_item"
          >
            <div class="ml2-item" @click="LinkAct(item)">
              <div class="ml2-item-box">
                <img v-if="item.banners" :src="item.banners"  v-load="'oActSmall'"/>
                <img v-else src="@/assets/img/empty.png" />
              </div>
            </div>
            <div class="ml2-item-info">
              <p>{{ item.title }}</p>
              <p class="md">Time：{{ item.dateTime }}</p>
            </div>
          </Col>
        </div>
        <div class="no_more mar_bot1" v-if="loading">
          <p>{{ $t('lang.loading')}}</p>
        </div>
         <!-- <div class="no_more mar_bot1" v-else-if="finished">
              <p>{{$t('lang.Its_the_end')}}</p>
        </div> -->
        <div
          class="more mar_bot1"
          @click="moreClick"
          v-else-if="!finished && list && list.length"
        >
          <p>{{$t('lang.loadMore')}}</p>
        </div>
        <!-- <div class="no_more mar_bot1" v-else>{{ $t('lang.No_data') }}！</div> -->
      </Row>
    </div>
  </div>
</template>
 
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "product-list",
  props: {},
  data() {
    return {
      list: [],
      totalCount: 0,
      pageIndex: 1,
      pageSize: 8,
      finished: false,
      loading: false,
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
    }),
    exhibitorId() {
      return this.$route.params.id;
    },
  },
  components: {},
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXSEn",
      LinkAct: "linkTo/LinkAct",
    }),
    async getData() {
      this.loading = true;
      let query = `
        query($query:QueryInput!){
          activityQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items{
                  auditState
                  id
                  title
                  banners
                  startTime	 #开始时间
                  endTime		 #结束时间
                  acvitityType #活动类型，0/ExternalLink：外链、1/Live：直播、 2/Registration：报名
                  externalLink #外链地址
                  stateIndex #活动进行状态 1：进行中、2：未开始：3：已结束
                  liveStateIndex
                  liveAuditState
                  hasLive
                  company{
                    id
                    name
                  }
                }
              }
            }
        }
      `;
      let where = {
        Equal: {
          n: "exhibitorId",
          v: this.exhibitorId,
        },
        EqualB: {
          n: "auditState",
          v: 1,
        },
        Equal3: {
          n: "isShow",
          v: 1,
        },
      };
      let order = [
        {
          N: "topicIndex",
          v: 0,
        },
        {
          N: "createAt",
          v: 0,
        },
      ];
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      let data = await this.graphqlPost(opt);
      // console.log(data,'活动')
      let res = JSON.parse(JSON.stringify(data.data.activityQuery.query));
      res.items.forEach((e) => {
        if (e.banners && e.banners.indexOf("[") != -1) {
          let banArr = JSON.parse(e.banners);
          e.banners = banArr[0];
        }
        let str = moment(e.startTime).format("YYYY/MM/DD");
        str += "~" + moment(e.endTime).format("YYYY/MM/DD");
        e.dateTime = str;
      });
      if (this.pageIndex == 1) {
        this.list = res.items;
      } else {
        this.list = [...new Set([...this.list, ...res.items])];
      }
      // console.log(this.list,'list')
      this.loading = false;
      if (!res.hasNextPage) {
        this.finished = true;
      }
    },
    //点击加载更多
    moreClick() {
      if (this.finished) return;
      this.pageIndex += 1;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.act_item {
  .ml2-item-box {
    border: none;
    width: 195px;
    height: 110px;
    padding-top: 0;
    > img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
  .ml2-item-info {
    p {
      text-align: left;
      &:first-child {
        font-weight: initial;
      }
      &.md {
        color: #999;
        font-size: 12px;
      }
    }
  }
}
</style> 