<template>
  <div class="m-card3" v-if="list && list.length">
    <div class="mc-top">
      <div class="mc-top-title">
        <span class="mc-top-text mc-top-txt s-bold">Products</span>
      </div>
    </div>
    <div class="m-list2">
      <Row>
        <div class="clear">
          <Col :key="'m-list2-1' + index" class="m-list2-box pro_item" span="6" v-for="(item, index) in list">
            <div @click="linkToProductDetais(item.id)">
              <div class="ml2-item">
                <div class="ml2-item-box">
                  <template v-if="item.video && JSON.parse(item.video).cover && JSON.parse(item.video).cover != 1">
                    <img :src="JSON.parse(item.video).cover" v-if="JSON.parse(item.video).cover" />
                    <img src="@/assets/img/empty.png" v-else />
                  </template>
                  <template v-else>
                    <img :src="item.banner[0]" v-if="item.banner[0]" v-load="'oPro'" />
                    <img src="@/assets/img/empty.png" v-else />
                  </template>
                  <div class="play_video" v-if="item.video && JSON.parse(item.video).url">
                    <i class="iconfont icon-shipinmianxing"></i>
                  </div>
                  <div class="rebang" v-if="item.isPopularize">
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <i class="iconfont icon-fire" style="font-size: 14px; margin-right: 2px"></i>
                      Hot
                    </div>
                  </div>
                  <div class="is_threeD" v-if="item.view3DUrl">
                    <i
                      class="iconfont icon-d"
                      style="
                        font-size: 12px;
                        margin-left: 6px;
                        margin-right: 4px;
                        color: #ff5c00;
                      "
                    ></i>
                    <span
                      style="
                        font-size: 12px;
                        color: #ff5c00;
                        display: inline-block;
                      "
                      >3D</span
                    >
                  </div>
                  <div class="qj_div" v-if="item.panoramaUrl">
                    <i class="iconfont icon-yuanxitubiao-"></i>
                  </div>
                </div>
              </div>
              <div class="ml2-item-info">
                <p style="text">{{ item.name }}</p>
                <p class="md">{{ item.selling }}</p>
              </div>
            </div>
          </Col>
        </div>
        <div class="no_more mar_bot1" v-if="loading">
          <p>{{ $t("lang.loading") }}</p>
        </div>
        <!-- <div class="no_more mar_bot1" v-else-if="finished">
              <p>{{$t('lang.Its_the_end')}}</p>
        </div>-->
        <div @click="moreClick" class="more mar_bot1" v-else-if="!finished && list && list.length">
          <p>{{ $t("lang.loadMore") }}</p>
        </div>
        <!-- <div class="no_more mar_bot1" v-else>{{ $t('lang.No_data') }}！</div> -->
      </Row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "product-list",
  props: {},
  data() {
    return {
      list: [],
      totalCount: 0,
      pageIndex: 1,
      pageSize: 8,
      finished: false,
      loading: false,
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
    }),
    exhibitorId() {
      return this.$route.params.id;
    },
  },
  components: {},
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXSEn",
      linkToProductDetais: "linkTo/linkToProductDetais",
    }),
    async getData() {
      this.loading = true;
      let query = `
        query($query:QueryInput!){
          productQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items{
                  id
                  name
                  banner
                  category
                  exhibitorId
                  isPopularize
                  panoramaUrl
                  exhibitor {
                    isLiving
                  }
                  selling
                  video
                  view3DUrl
                  region
                }
              }
            }
        }
      `;
      let where = {
        Equal: {
          n: "exhibitorId",
          v: this.exhibitorId,
        },
        Equal2: {
          n: "isShow",
          v: 1,
        },
        EqualB: {
          n: "auditState",
          v: 1,
        },
      };
      let order = [
        {
          N: "showIndex",
          v: 0,
        },
        {
          N: "createAt",
          v: 0,
        },
      ];
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      let data = await this.graphqlPost(opt);
      let res = JSON.parse(JSON.stringify(data.data.productQuery.query));
      res.items.forEach((e) => {
        if (e.banner && !Array.isArray(e.banner)) {
          e.banner = JSON.parse(e.banner);
        } else if (!Array.isArray(e.banner)) {
          e.banner = [];
        }
      });
      if (this.pageIndex == 1) {
        this.list = res.items;
      } else {
        this.list = [...new Set([...this.list, ...res.items])];
      }
      this.loading = false;
      if (!res.hasNextPage) {
        this.finished = true;
      }
    },
    //点击加载更多
    moreClick() {
      if (this.finished) return;
      this.pageIndex += 1;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.pro_item {
  .ml2-item-box {
    border: none;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
    .play_video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
      i {
        font-size: 48px;
        color: #fff;
      }
    }
    .rebang {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 48px;
      height: 20px;
      @include background_color(#ff5e00);
      color: #fff;
      font-size: 12px;
    }
    .is_threeD {
      position: absolute;
      top: 0;
      right: 0px;
      width: 42px;
      height: 20px;
      background-color: #fff;
      border-radius: 0px 0px 0px 5px;
      border: solid 1px #ff5c00;
      display: flex;
      align-items: center;
    }
    .qj_div {
      position: absolute;
      right: 15px;
      bottom: 15px;
      background: rgba($color: #000, $alpha: 0.4);
      border-radius: 50%;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5;
      i {
        color: #fff;
        font-size: 24px;
      }
    }
  }
  .ml2-item-info {
    p {
      text-align: left;
      &:first-child {
        font-weight: initial;
      }
      &.md {
        color: #999;
        font-size: 12px;
      }
    }
  }
}
</style>
